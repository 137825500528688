// pages/404.tsx
import { useEffect } from 'react';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';

export default function Custom404() {
  const router = useRouter();
  useEffect(() => {
    router.push('/');
  }, []);
  return null;
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', [
        'common',
        'system-status',
      ])),
    },
  };
};
